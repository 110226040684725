<template>
    <div class="fullpage">
        <Header 
            :activeAbout="activeAbout"
            :activeExperience="activeExperience"
            :activeProjects="activeProjects"
            :activeContact="activeContact"/>
        <div class="effectBox">
            <div class="boxAnimation"></div>
            <div class="boxAnimation"></div>
        </div>
        <div class="title">
            <h2 data-text="Frontend Developer">Frontend Developer</h2>
        </div>

        <section class="showhide about" :class="sectionAbout ? 'open' : ''">
            <div class="content_about">
                <div class="content_1">
                    <h2>About me</h2>
                    <p>I have over 5 years of experience working with Angular, Vue, and web3, as well as a strong skill set in animation JavaScript. My proficiency in creating visually stunning and intuitive user interfaces, combined with my knowledge of blockchain technology makes me a valuable asset to any project seeking innovative solutions.</p>
                </div>
            </div>
            <div class="avatar">
                <div class="imgAvatar">
                    <img src="../assets/avatar_crop.png" alt="">
                </div>
            </div>
        </section>

        <section class="showhide experience" :class="sectionExperience ? 'open' : ''">
            <div class="content_experience">
                <h2>Work Experience</h2>
                <div class="listExp">
                    <div class="item" :class="item.active ? 'active' : ''" v-for="(item, index) in experience" :key="index">
                        <h4>{{ item.time}}</h4>
                        <div class="content">
                            <h2>{{ item.companyName }}</h2>
                            <p>{{ item.responsibility}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="showhide projects" :class="sectionProjects ? 'open' : ''">
            <div class="content_projects">
                <h2>Projects</h2>
                <div class="listProjects" >
                    <div class="item" v-for="item in projects" :key="item.id">
                        <a :href="item.url" target="_blank"  >
                            <img :src="require('../assets/' + item.img)" alt="">
                            <h2>{{ item.name }}</h2>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="showhide contact" :class="sectionContact ? 'open' : ''">
            <div class="content_contact">
                <h2>Contact</h2>
                <ul>
                    <li><span>Gmail:</span> thangtran070495@gmail.com</li>
                    <li><span>Linkedin:</span> <a href="https://www.linkedin.com/in/tom-tran-b713bb221/" target="_blank">Tom Tran</a> </li>
                    <li><span>Facebook:</span> <a href="https://www.facebook.com/thangtran0704" target="_blank">Thang Tran (Tom)</a></li>
                    <li><span>Zalo:</span> @thangtran0704</li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from "../components/Header.vue";
    export default {

        components: { Header },

        data() {
            return {
                sectionAbout: true,
                sectionExperience: false,
                sectionProjects: false,
                sectionContact: false,
                projects: [
                    {
                        name: 'Swish',
                        img: 'swish.jpg',
                        url: 'https://www.swish.fun/',
                    },
                    {
                        name: 'Momoka',
                        img: 'momoka.jpg',
                        url: 'https://momoka-hoikuen.jp/',
                    },
                    {
                        name: 'Ohbara',
                        img: 'ohbara.jpg',
                        url: 'https://momoka-hoikuen.jp/ohbara/blog/',
                    },
                    {
                        name: 'Whydah',
                        img: 'krabot.jpg',
                        url: 'https://www.whydah.xyz/',
                    },
                    {
                        name: 'Krabot',
                        img: 'krabot.jpg',
                        url: 'hhttps://krabots.io/',
                    },
                    {
                        name: 'Indetail',
                        img: 'indetail.jpg',
                        url: 'https://www.indetail.co.jp/',
                    },
                    {
                        name: 'Akasakazouen',
                        img: 'akasaka.jpg',
                        url: 'https://akasakazouen.com/',
                    },
                    {
                        name: 'Oku',
                        img: 'oku.jpg',
                        url: 'https://oku-k.co.jp/',
                    },
                    {
                        name: 'Idamura',
                        img: 'idamura.jpg',
                        url: 'https://idamura.xyz/',
                    },
                    {
                        name: 'Ortho',
                        img: 'ortho.jpg',
                        url: 'https://ortho.fashion/',
                    },
                    {
                        name: 'Defi Summer',
                        img: 'defi.jpg',
                        url: 'https://summer.kaidex.io/',
                    },
                ],
                experience:[
                    {
                        time: '2021 - Now',
                        companyName: 'Karidachain',
                        responsibility: 'Responsibility in projects is build full web by Vuejs, Web3js, convert figma design into web.',
                        active: true,
                    },
                    {
                        time: '2019 - 2021',
                        companyName: 'System EXE',
                        responsibility: 'Responsibility in projects is convert source FLEX into Anguular 8.',
                        active: false
                    },
                    {
                        time: '2018 - 2019',
                        companyName: 'MOR Software JSC',
                        responsibility: 'Responsibility in projects is convert all interfaces from PSD file to HTML file.',
                        active: false
                    },
                    {
                        time: '2017 - 2018',
                        companyName: 'HBB Solution',
                        responsibility: 'Responsibility in projects is convert all interfaces from PSD file to HTML file.Design layout by photoshop.',
                        active: false
                    },
                ]
            };
        },
        methods: {
            activeAbout(){
                this.sectionAbout = true;
                this.sectionExperience = false;
                this.sectionProjects = false;
                this.sectionContact = false;
            },
            activeExperience(){
                this.sectionAbout = false;
                this.sectionExperience = true;
                this.sectionProjects = false;
                this.sectionContact = false;
            },
            activeProjects(){
                this.sectionAbout = false;
                this.sectionExperience = false;
                this.sectionProjects = true;
                this.sectionContact = false;
            },
            activeContact(){
                this.sectionAbout = false;
                this.sectionExperience = false;
                this.sectionProjects = false;
                this.sectionContact = true;
            }
        },
    }
</script>

<style lang="scss" scoped>
    // $color: #FB2576;
    $color: #03C988;

    @keyframes gif {
        0%{
            border-radius: 71% 29% 37% 63% / 45% 50% 50% 55% ;
        }
        30%{
            border-radius: 30% 70% 11% 89% / 69% 35% 65% 31% ;
        }
        50%{
            border-radius: 70% 30% 39% 61% / 85% 24% 76% 15% ;
        }
        70%{
            border-radius: 46% 54% 39% 61% / 85% 44% 56% 15%  ;
        }
        100%{
            border-radius: 71% 29% 37% 63% / 45% 50% 50% 55% ;
        }
    }

    @keyframes aniText {
        0%, 10%, 100%{
            width: 0%;
        }
        70%, 90%{
            width: 100%;
        }
    }
    .fullpage{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        position: relative;
        @media (max-width: 992px){
            height: auto;
            padding-bottom: 100px;
        }
        .title{
            position: fixed;
            top: 26px;
            right: 70px;
            // transform: translateX(-50%);
            z-index: 999;
            display: none;
            h2{
                margin: 0;
                font-family: 'Poppins', sans-serif;
                color: #000;
                text-transform: uppercase;
                font-size: 30px;
                -webkit-text-stroke: 3px #383d52;
                white-space: nowrap;
                &::before{
                    content: attr(data-text);
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    color: $color;
                    -webkit-text-stroke: 0px #383d52;
                    border-right: 2px solid $color;
                    white-space: nowrap;
                    overflow: hidden;
                    animation: aniText 10s linear  infinite;
                }
            }
            @media (max-width: 992px){
                display: block;
                right: 50%;
                transform: translateX(50%);
                background: #13005A;
                h2{
                    font-size: 25px;
                }
                
            }
        }
        .effectBox{
            .boxAnimation{
                position: absolute;
                width: 300px;
                height: 300px;
                background: $color;
                opacity: .3;
                transition: all 1s ease;
                animation: gif 5s infinite;
                border-radius: 71% 29% 37% 63% / 45% 50% 50% 55% ;
                &:nth-child(1){
                    bottom: -50px;
                    left: -70px;
                }
                &:nth-child(2){
                    left: unset;
                    bottom: unset;
                    right: -120px;
                    top: 50px;
                    opacity: .5;
                    animation: gif 7s infinite;
                }
                @media (max-width: 992px){
                    display: none;
                    &:first-child{
                        bottom: -10px;
                    }
                    &:last-child{
                        opacity: .4;
                        right: -170px;
                    }
                }
            }
        }
        .showhide{
            display: flex;
            position: absolute;
            top: 0;
            height: 100vh;
            align-items: center;
            gap: 50px;
            margin: auto;
            transition: all 1s ease;
            transform: skew(70deg, 20deg);
            opacity: 0;
            visibility: hidden;
            &.open{
                opacity: 1;
                visibility: visible;
                transform: skew(0deg, 0deg);  
            }
            @media (max-width: 992px){
                transition: all 2s ease;
                transform: rotateZ(90deg) scale(0);
                height: auto;
                &.open{
                    transform: rotateZ(0deg) scale(1);
                }
            }
        }
        .about{
            width: 60%;
            .content_about{
                .content_1{
                    h2{
                        font-family: 'Tilt Neon', cursive;
                        color: $color;
                        font-size: 46px;
                        margin-bottom: 20px;
                        letter-spacing: 2px;
                    }
                    p{
                        font-size: 18px;
                        color: #fff;
                        line-height: 28px;
                        margin: 0;
                    }
                }
            }
            .avatar{
                .imgAvatar{
                    position: relative;
                    width: 250px;
                    height: 250px;
                    border-radius: 19% 81% 70% 30% / 63% 44% 56% 37%;
                    border:4px solid $color;
                    img{
                        position: absolute;
                        bottom: -2px;
                        right: -13px;
                        border-radius: 19% 81% 70% 30%/63% 44% 56% 37%;
                        width: 290px;
                        z-index: 1;
                        overflow: hidden;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        bottom: -3px;
                        right: -1px;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to top, $color, transparent);
                        border-radius: 19% 81% 70% 30% / 63% 44% 56% 37%;

                        z-index: 1000;
                    }
                }
            }
        }
        .experience{
            width: 50%;
            .content_experience{
                >h2{
                    font-family: 'Tilt Neon', cursive;
                    color: $color;
                    font-size: 46px;
                    margin-bottom: 40px;
                    letter-spacing: 2px;
                    margin-top: 100px;
                }
                .listExp{
                    .item{
                        display: flex;
                        align-items: center;
                        margin-bottom: 25px;
                        cursor: default;
                        h4{
                            color: #7d7d7d;
                            font-size: 16px;
                            margin: 0;
                            width: 150px;
                            position: relative;
                            transition: all .5s ease;
                            &::after{
                                position: absolute;
                                content: '';
                                width: 10px;
                                aspect-ratio: 1;
                                background: #7d7d7d;
                                left: -30px;
                                top: 7px;
                                border-radius: 50%;
                            }
                            &::before{
                                position: absolute;
                                content: "";
                                width: 1px;
                                height: 95px;
                                left: -26px;
                                background: #7d7d7d;
                                top: 10px;
                            }
                        }
                        &:last-child{
                            h4{
                                &::before{
                                    display: none;
                                }
                            }
                        }
                        .content{
                            flex: 1;
                            h2{
                                font-family: 'Tilt Neon', cursive;
                                color: #7d7d7d;
                                font-size: 25px;
                                margin-bottom: 5px;
                                letter-spacing: 2px;
                                transition: all .5s ease;

                            }
                            p{
                                font-size: 16px;
                                transition: all .5s ease;
                                line-height: 22px;
                                color: #7d7d7d;
                                margin: 0;
                            }
                        }
                        &.active, &:hover{
                            h4{
                                color: #fff;
                                &::after{
                                    background: $color;
                                }
                            }
                            .content{
                                h2, p{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .projects{
            justify-content: center;
            width: 70%;
            .content_projects{
                >h2{
                    font-family: 'Tilt Neon', cursive;
                    color: $color;
                    font-size: 46px;
                    margin-top: 50px;
                    letter-spacing: 2px;
                    text-align: center;
                    margin-bottom: 50px;
                }
                .listProjects{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 30px;
                    .item{
                        width: calc((100% / 4) - 30px);
                        position: relative;
                        border-radius: 5px;
                        img{
                            width: 100%;
                            transition: .75s;
                            border-radius: 5px;
                        }
                        @media (min-width: 993px){
                            h2{
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                text-align: center;
                                font-size: 20px;
                                color: #fff;
                                transition: all .75s ease;
                                opacity: 0;
                                visibility: hidden;
                                z-index: -1;
                            }
                            &:hover{
                                img{
                                    transform: perspective(10px)
                                                rotateX(2deg)
                                                translateY(-30px);
                                }
                                h2{
                                    opacity: 1;
                                    visibility: visible;
                                    z-index: 2;
                                    bottom: -10px;
                                }
                            }
                        }
                    }
                    @media (max-width: 992px){
                        gap: 20px;
                        .item{
                            width: calc((100% / 2) - 10px);
                            h2{
                                color: #fff;
                                font-size: 16px;
                                text-align: center;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
        .contact{
            justify-content: center;
            width: 70%;
            .content_contact{
                h2{
                    font-family: 'Tilt Neon', cursive;
                    color: $color;
                    font-size: 46px;
                    margin-bottom: 40px;
                    letter-spacing: 2px;
                    text-align: center;
                    margin-bottom: 50px;
                }
                ul{
                    li{
                        display: flex;
                        align-items: center;
                        color: #fff;
                        font-size: 22px;
                        margin-bottom: 10px;
                        >span{
                            min-width: 150px;
                        }
                        a{
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            .about{
                flex-direction: column-reverse;
                width: 90%;
                padding-top: 150px;
                padding-bottom: 100px;
                .content_about{
                    .content_1{
                        p{
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
            }
            .experience{
                width: 80%;
                padding-bottom: 100px;
                .content_experience{
                    >h2{
                        font-size: 30px;
                        margin-top: 100px;
                        width: 100%;
                        text-align: center;
                    }
                    .listExp{
                        .item{
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            h4{
                                &::before{
                                    height: 150px;
                                }
                            }
                            .content{
                                h2{
                                    margin-top: 5px;
                                }
                                p{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
            .projects{
                width: 90%;
                padding-bottom: 100px;
                .content_projects{
                    >h2{
                        margin-top: 100px;
                    }
                    .listProjects{
                        
                    }
                }
            }
            .contact{
                width: 90%;
                height: calc(100vh - 120px);
                .content_contact{
                    ul{
                        li{
                            font-size: 14px;
                            span{
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>