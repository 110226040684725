<template>
    <div class="header">
        <div class="nav">
            <ul>
                <li>
                    <a class="logo" href="/">Tom Tran</a>
                </li>
                <li>
                    <a @click="changeTab('about')" :class="flgAbout ? 'active' : ''">
                        <ion-icon name="person-outline"></ion-icon> About me</a>
                </li>
                <li>
                    <a @click="changeTab('experience')" :class="flgExperience ? 'active' : ''">
                        <ion-icon name="calendar-outline"></ion-icon> Experience</a>
                </li>
                <li>
                    <a @click="changeTab('projects')" :class="flgProjects ? 'active' : ''">
                        <ion-icon name="server-outline"></ion-icon> Projects</a>
                </li>
                <li>
                    <a @click="changeTab('contact')" :class="flgContact ? 'active' : ''">
                        <ion-icon name="navigate-outline"></ion-icon> Contact</a>
                </li>
                
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['activeAbout', 'activeExperience', 'activeProjects', 'activeContact'],
        data(){
            return {
                flgAbout: true,
                flgExperience: false,
                flgProjects: false,
                flgContact: false,
            }
        },
        methods:{
            changeTab(tab){
                if(tab == 'about'){
                    this.flgAbout = true;
                    this.flgExperience = false;
                    this.flgProjects = false;
                    this.flgContact = false;
                    this.activeAbout();
                }
                else if(tab == 'experience'){
                    this.flgAbout = false;
                    this.flgExperience = true;
                    this.flgProjects = false;
                    this.flgContact = false;
                    this.activeExperience();
                }
                else if(tab == 'projects'){
                    this.flgAbout = false;
                    this.flgExperience = false;
                    this.flgProjects = true;
                    this.flgContact = false;
                    this.activeProjects();
                }
                else if(tab == 'contact'){
                    this.flgAbout = false;
                    this.flgExperience = false;
                    this.flgProjects = false;
                    this.flgContact = true;
                    this.activeContact();
                }
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    // $color: #FB2576;
    $color: #03C988;
    @keyframes effect {
        0%{
            text-shadow: 6px 0px 0px #ff0000,
                -6px -4px 0px #00ff00,
                4px 6px 0px #0000ff;
        }
        14%{
            text-shadow: 6px 0px 0px #ff0000,
                -6px -4px 0px #00ff00,
                4px 6px 0px #0000ff;
        }
        15%{
            text-shadow: -6px -4px 0px #ff0000,
                4px 4px 0px #00ff00,
                -6px -6px 0px #0000ff;
        }
        49%{
            text-shadow: -6px -4px 0px #ff0000,
                4px 4px 0px #00ff00,
                -6px -6px 0px #0000ff;
        }
        50%{
            text-shadow: 4px 6px 0px #ff0000,
                4px 4px 0px #00ff00,
                0px -6px 0px #0000ff;
        }
        99%{
            text-shadow: 4px 6px 0px #ff0000,
                4px 4px 0px #00ff00,
                0px -6px 0px #0000ff;
        }
        100%{
            text-shadow: -4px 0px 0px #ff0000,
                -4px -4px 0px #00ff00,
                -4px -6px 0px #0000ff;
        }
    }
    .header{
        position: fixed;
        z-index: 99999;
        padding: 20px 70px 0px;
        width: 100%;
        top: 0;
        .nav{
            width: 100%;
            ul{
                display: flex;
                align-items: center;
                width: 100%;
                gap: 30px;
                li{
                    color: #fff;
                    a{
                        color: #fff;
                        font-size: 22px;
                        text-decoration: none;
                        font-family: 'Tilt Neon', cursive;
                        letter-spacing: 2px;
                        cursor: pointer;
                        ion-icon{
                            display: none;
                        }
                        @media (min-width: 993px){
                            &:hover{
                                color: $color;

                            }
                        }
                        &.active{
                            color: $color;
                        }
                    }
                    &:first-child{
                        a.logo{
                            font-family: 'Rampart One', cursive;
                            text-transform: uppercase;
                            font-weight: bold;
                            font-size: 30px;
                            margin-right: 100px;
                            letter-spacing: 2px;
                            position: relative;
                            white-space: nowrap;
                            // &:hover{
                            //     color: unset;
                            // }
                            // &::after, &::before{
                            //     position: relative;
                            //     position: absolute;
                            //     top: 0;
                            //     left: 0;
                            //     letter-spacing: 2px;
                            //     opacity: .7;
                            //     content: 'Tom Tran';
                            // }
                            // &::before{
                            //     animation: effect 675ms infinite;
                            //     transform: translate(-3px, -3px);
                            //     clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
                            // }
                            // &::after{
                            //     animation: effect 333ms infinite;
                            //     transform: translate(3px, 3px);
                            //     clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
                            // }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            top: unset;
            bottom: 0;
            padding: 0px 0 10px;
            background: #fff;
            border-radius: 8px 8px 0 0;
            .nav{
                ul{
                    justify-content: space-evenly;
                    li{
                        &:first-child{
                            display: none;
                        }
                        a{
                            font-size: 12px;
                            color: #000;
                            text-align: center;
                            display: block;
                            padding-top: 10px;
                            border-top: 2px solid transparent;
                            letter-spacing: 0;
                            &.active{
                                border-top: 2px solid;
                            }
                            ion-icon{
                                display: block;
                                margin: auto;
                                font-size: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
